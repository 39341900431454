import * as React from 'react';
import { useStore } from 'react-admin';
import { useQuery } from '@tanstack/react-query';
// import CustomOnlineStoreItem from './CustomOnlineStoreItem';

const CustomOnlineStore = () => {
    //console.log('CustomOnlineStore');
    const token = () => localStorage.getItem('token');
    const [storeGetFromServer, setStoreGetFromServer] = useStore(
        `AspaProfile.fetched`,
        '0'
    );

    const url = '/api/aspa_profile';

    const [storeUserPDC, setStoreUserPDC] = useStore(
        `preferences.user.datagrid.columns`,
        ''
    );
    const [storeUserLP, setStoreUserLP] = useStore(`user.listParams`, '');
    const [storeUserFL, setStoreUserFL] = useStore(`user.filterList`, '');
    const [storeUserSQ, setStoreUserSQ] = useStore(`user.savedQueries`, '');
    const [storeTeacherPDC, setStoreTeacherPDC] = useStore(
        `preferences.teacher.datagrid.columns`,
        ''
    );
    const [storeTeacherLP, setStoreTeacherLP] = useStore(
        `teacher.listParams`,
        ''
    );
    const [storeTeacherFL, setStoreTeacherFL] = useStore(
        `teacher.filterList`,
        ''
    );
    const [storeTeacherFSQ, setStoreTeacherFSQ] = useStore(
        `teacher.filters.schools_q`,
        ''
    );
    const [storeTeacherFSR, setStoreTeacherFSR] = useStore(
        `teacher.filters.schools_result`,
        ''
    );
    const [storeTeacherSQ, setStoreTeacherSQ] = useStore(
        `teacher.savedQueries`,
        ''
    );
    const [storeStudentPDC, setStoreStudentPDC] = useStore(
        `preferences.student.datagrid.columns`,
        ''
    );
    const [storeStudentLP, setStoreStudentLP] = useStore(
        `student.listParams`,
        ''
    );
    const [storeStudentFL, setStoreStudentFL] = useStore(
        `student.filterList`,
        ''
    );
    const [storeStudentFSQ, setStoreStudentFSQ] = useStore(
        `student.filters.schools_q`,
        ''
    );
    const [storeStudentFSR, setStoreStudentFSR] = useStore(
        `student.filters.schools_result`,
        ''
    );
    const [storeStudentSQ, setStoreStudentSQ] = useStore(
        `student.savedQueries`,
        ''
    );
    const [storePupilPDC, setStorePupilPDC] = useStore(
        `preferences.pupil.datagrid.columns`,
        ''
    );
    const [storePupilLP, setStorePupilLP] = useStore(`pupil.listParams`, '');
    const [storePupilFL, setStorePupilFL] = useStore(`pupil.filterList`, '');
    const [storePupilFSQ, setStorePupilFSQ] = useStore(
        `pupil.filters.schools_q`,
        ''
    );
    const [storePupilFSR, setStorePupilFSR] = useStore(
        `pupil.filters.schools_result`,
        ''
    );
    const [storePupilSQ, setStorePupilSQ] = useStore(
        `pupil.savedQueries`,
        ''
    );
    const [storeProductPDC, setStoreProductPDC] = useStore(
        `preferences.product.datagrid.columns`,
        ''
    );
    const [storeProductLP, setStoreProductLP] = useStore(
        `product.listParams`,
        ''
    );
    const [storeProductFL, setStoreProductFL] = useStore(
        `product.filterList`,
        ''
    );
    const [storeProductSQ, setStoreProductSQ] = useStore(
        `product.savedQueries`,
        ''
    );
    const [storeSubscriptionPDC, setStoreSubscriptionPDC] = useStore(
        `preferences.subscription.datagrid.columns`,
        ''
    );
    const [storeSubscriptionLP, setStoreSubscriptionLP] = useStore(
        `subscription.listParams`,
        ''
    );
    const [storeSubscriptionFL, setStoreSubscriptionFL] = useStore(
        `subscription.filterList`,
        ''
    );
    const [storeSubscriptionFPQ, setStoreSubscriptionFPQ] = useStore(
        `subscription.filters.product_quid_q`,
        ''
    );
    const [storeSubscriptionFPR, setStoreSubscriptionFPR] = useStore(
        `subscription.filters.product_quid_result`,
        ''
    );
    const [storeSubscriptionFSQ, setStoreSubscriptionFSQ] = useStore(
        `subscription.filters.subscribers_q`,
        ''
    );
    const [storeSubscriptionFSR, setStoreSubscriptionFSR] = useStore(
        `subscription.filters.subscribers_result`,
        ''
    );
    const [storeSubscriptionSQ, setStoreSubscriptionSQ] = useStore(
        `subscription.savedQueries`,
        ''
    );
    const [storeSchoolPDC, setStoreSchoolPDC] = useStore(
        `preferences.school.datagrid.columns`,
        ''
    );
    const [storeSchoolLP, setStoreSchoolLP] = useStore(`school.listParams`, '');
    const [storeSchoolFL, setStoreSchoolFL] = useStore(`school.filterList`, '');
    // const [storeSchoolPSL,setStoreSchoolPSL] = useStore(`school.pupil.subscription.listParams`, "");
    // const [storeSchoolTSL,setStoreSchoolTSL] = useStore(`school.teacher.subscription.listParams`, "");
    const [storeSchoolSQ, setStoreSchoolSQ] = useStore(
        `school.savedQueries`,
        ''
    );
    const [storeGroupPDC, setStoreGroupPDC] = useStore(
        `preferences.group.datagrid.columns`,
        ''
    );
    const [storeGroupLP, setStoreGroupLP] = useStore(`group.listParams`, '');
    const [storeGroupFL, setStoreGroupFL] = useStore(`group.filterList`, '');
    const [storeGroupFSQ, setStoreGroupFSQ] = useStore(
        `group.filters.schools_q`,
        ''
    );
    const [storeGroupFSR, setStoreGroupFSR] = useStore(
        `group.filters.schools_result`,
        ''
    );
    const [storeGroupSQ, setStoreGroupSQ] = useStore(
        `group.savedQueries`,
        ''
    );
    const [storeAnnouncementPDC, setStoreAnnouncementPDC] = useStore(
        `preferences.announcement.datagrid.columns`,
        ''
    );
    const [storeAnnouncementLP, setStoreAnnouncementLP] = useStore(
        `announcement.listParams`,
        ''
    );
    const [storeAnnouncementFL, setStoreAnnouncementFL] = useStore(
        `announcement.filterList`,
        ''
    );
    const [storeAnnouncementSQ, setStoreAnnouncementSQ] = useStore(
        `announcement.savedQueries`,
        ''
    );

    const json = {
        'preferences.user.datagrid.columns': storeUserPDC,
        'user.listParams': storeUserLP,
        'user.filterList': storeUserFL,
        'user.savedQueries': storeUserSQ,
        'preferences.teacher.datagrid.columns': storeTeacherPDC,
        'teacher.listParams': storeTeacherLP,
        'teacher.filterList': storeTeacherFL,
        'teacher.filters.schools_q': storeTeacherFSQ,
        'teacher.filters.schools_result': storeTeacherFSR,
        'teacher.savedQueries': storeTeacherSQ,
        'preferences.student.datagrid.columns': storeStudentPDC,
        'student.listParams': storeStudentLP,
        'student.filterList': storeStudentFL,
        'student.filters.schools_q': storeStudentFSQ,
        'student.filters.schools_result': storeStudentFSR,
        'student.savedQueries': storeStudentSQ,
        'preferences.pupil.datagrid.columns': storePupilPDC,
        'pupil.listParams': storePupilLP,
        'pupil.filterList': storePupilFL,
        'pupil.filters.schools_q': storePupilFSQ,
        'pupil.filters.schools_result': storePupilFSR,
        'pupil.savedQueries': storePupilSQ,
        'preferences.product.datagrid.columns': storeProductPDC,
        'product.listParams': storeProductLP,
        'product.filterList': storeProductFL,
        'product.savedQueries': storeProductSQ,
        'preferences.subscription.datagrid.columns': storeSubscriptionPDC,
        'subscription.listParams': storeSubscriptionLP,
        'subscription.filterList': storeSubscriptionFL,
        'subscription.filters.product_quid_q': storeSubscriptionFPQ,
        'subscription.filters.product_quid_result': storeSubscriptionFPR,
        'subscription.filters.subscribers_q': storeSubscriptionFSQ,
        'subscription.filters.subscribers_result': storeSubscriptionFSR,
        'subscription.savedQueries': storeSubscriptionSQ,
        'preferences.school.datagrid.columns': storeSchoolPDC,
        'school.listParams': storeSchoolLP,
        'school.filterList': storeSchoolFL,
        'school.savedQueries': storeSchoolSQ,
        // "school.pupil.subscription.listParams": storeSchoolPSL,
        // "school.teacher.subscription.listParams": storeSchoolTSL,
        'preferences.group.datagrid.columns': storeGroupPDC,
        'group.listParams': storeGroupLP,
        'group.filterList': storeGroupFL,
        'group.filters.schools_q': storeGroupFSQ,
        'group.filters.schools_result': storeGroupFSR,
        'group.savedQueries': storeGroupSQ,
        'preferences.announcement.datagrid.columns': storeAnnouncementPDC,
        'announcement.listParams': storeAnnouncementLP,
        'announcement.filterList': storeAnnouncementFL,
        'announcement.savedQueries': storeAnnouncementSQ,
    };

    let requestOptionsGET = () => {
        return {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token(),
            },
        };
    };

    let requestOptionsPUT = () => {
        return {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token(),
            },
            body: JSON.stringify(json),
        };
    };

    let fetchFromServer = true;
    if (
        parseInt(storeGetFromServer) >
        parseInt(new Date(Date.now()).setMinutes(-2).toString())
    ) {
        fetchFromServer = false;
    }

    const { data } = useQuery({
        queryKey: ['store'],
        queryFn: () =>
            fetch(
                url,
                fetchFromServer === true
                    ? requestOptionsGET()
                    : requestOptionsPUT()
            )
                .then(res => res.json())
                .then(data => {
                    if (fetchFromServer === true) {
                        data.profile['preferences.user.datagrid.columns'] &&
                            data.profile[
                                'preferences.user.datagrid.columns'
                            ] !== '' &&
                            setStoreUserPDC(
                                data.profile[
                                    'preferences.user.datagrid.columns'
                                ]
                            );
                        data.profile['user.listParams'] &&
                            data.profile['user.listParams'] !== '' &&
                            setStoreUserLP(data.profile['user.listParams']);
                        data.profile['user.filterList'] &&
                            data.profile['user.filterList'] !== '' &&
                            setStoreUserFL(data.profile['user.filterList']);
                        data.profile['user.savedQueries'] &&
                            data.profile['user.savedQueries'] !== '' &&
                            setStoreUserSQ(
                                data.profile['user.savedQueries']
                            );
                        data.profile['preferences.teacher.datagrid.columns'] &&
                            data.profile[
                                'preferences.teacher.datagrid.columns'
                            ] !== '' &&
                            setStoreTeacherPDC(
                                data.profile[
                                    'preferences.teacher.datagrid.columns'
                                ]
                            );
                        data.profile['teacher.listParams'] &&
                            data.profile['teacher.listParams'] !== '' &&
                            setStoreTeacherLP(
                                data.profile['teacher.listParams']
                            );
                        data.profile['teacher.filterList'] &&
                            data.profile['teacher.filterList'] !== '' &&
                            setStoreTeacherFL(
                                data.profile['teacher.filterList']
                            );
                        data.profile['teacher.filters.schools_q'] &&
                            data.profile['teacher.filters.schools_q'] !== '' &&
                            setStoreTeacherFSQ(
                                data.profile['teacher.filters.schools_q']
                            );
                        data.profile['teacher.filters.schools_result'] &&
                            data.profile['teacher.filters.schools_result'] !==
                                '' &&
                            setStoreTeacherFSR(
                                data.profile['teacher.filters.schools_result']
                            );
                        data.profile['teacher.savedQueries'] && data.profile['teacher.savedQueries'] !== '' && setStoreTeacherSQ(data.profile['teacher.savedQueries']);
                        data.profile['preferences.student.datagrid.columns'] &&
                            data.profile[
                                'preferences.student.datagrid.columns'
                            ] !== '' &&
                            setStoreStudentPDC(
                                data.profile[
                                    'preferences.student.datagrid.columns'
                                ]
                            );
                        data.profile['student.listParams'] &&
                            data.profile['student.listParams'] !== '' &&
                            setStoreStudentLP(
                                data.profile['student.listParams']
                            );
                        data.profile['student.filterList'] &&
                            data.profile['student.filterList'] !== '' &&
                            setStoreStudentFL(
                                data.profile['student.filterList']
                            );
                        data.profile['student.filters.schools_q'] &&
                            data.profile['student.filters.schools_q'] !== '' &&
                            setStoreStudentFSQ(
                                data.profile['student.filters.schools_q']
                            );
                        data.profile['student.filters.schools_result'] &&
                            data.profile['student.filters.schools_result'] !==
                                '' &&
                            setStoreStudentFSR(
                                data.profile['student.filters.schools_result']
                            );
                        data.profile['student.savedQueries'] && data.profile['student.savedQueries'] !== '' && setStoreStudentSQ(data.profile['student.savedQueries']);
                        data.profile['preferences.pupil.datagrid.columns'] &&
                            data.profile[
                                'preferences.pupil.datagrid.columns'
                            ] !== '' &&
                            setStorePupilPDC(
                                data.profile[
                                    'preferences.pupil.datagrid.columns'
                                ]
                            );
                        data.profile['pupil.listParams'] &&
                            data.profile['pupil.listParams'] !== '' &&
                            setStorePupilLP(data.profile['pupil.listParams']);
                        data.profile['pupil.filterList'] &&
                            data.profile['pupil.filterList'] !== '' &&
                            setStorePupilFL(data.profile['pupil.filterList']);
                        data.profile['pupil.filters.schools_q'] &&
                            data.profile['pupil.filters.schools_q'] !== '' &&
                            setStorePupilFSQ(
                                data.profile['pupil.filters.schools_q']
                            );
                        data.profile['pupil.filters.schools_result'] &&
                            data.profile['pupil.filters.schools_result'] !==
                                '' &&
                            setStorePupilFSR(
                                data.profile['pupil.filters.schools_result']
                            );
                        data.profile['pupil.savedQueries'] && data.profile['pupil.savedQueries'] !== '' && setStorePupilSQ(data.profile['pupil.savedQueries']);
                        data.profile['preferences.product.datagrid.columns'] &&
                            data.profile[
                                'preferences.product.datagrid.columns'
                            ] !== '' &&
                            setStoreProductPDC(
                                data.profile[
                                    'preferences.product.datagrid.columns'
                                ]
                            );
                        data.profile['product.listParams'] &&
                            data.profile['product.listParams'] !== '' &&
                            setStoreProductLP(
                                data.profile['product.listParams']
                            );
                        data.profile['product.filterList'] &&
                            data.profile['product.filterList'] !== '' &&
                            setStoreProductFL(
                                data.profile['product.filterList']
                            );
                        data.profile['product.savedQueries'] &&
                            data.profile['product.savedQueries'] !== '' &&
                            setStoreProductSQ(
                                data.profile['product.savedQueries']
                            );
                        data.profile[
                            'preferences.subscription.datagrid.columns'
                        ] &&
                            data.profile[
                                'preferences.subscription.datagrid.columns'
                            ] !== '' &&
                            setStoreSubscriptionPDC(
                                data.profile[
                                    'preferences.subscription.datagrid.columns'
                                ]
                            );
                        data.profile['subscription.listParams'] &&
                            data.profile['subscription.listParams'] !== '' &&
                            setStoreSubscriptionLP(
                                data.profile['subscription.listParams']
                            );
                        data.profile['subscription.filterList'] &&
                            data.profile['subscription.filterList'] !== '' &&
                            setStoreSubscriptionFL(
                                data.profile['subscription.filterList']
                            );
                        data.profile['subscription.filters.product_quid_q'] &&
                            data.profile[
                                'subscription.filters.product_quid_q'
                            ] !== '' &&
                            setStoreSubscriptionFPQ(
                                data.profile[
                                    'subscription.filters.product_quid_q'
                                ]
                            );
                        data.profile[
                            'subscription.filters.product_quid_result'
                        ] &&
                            data.profile[
                                'subscription.filters.product_quid_result'
                            ] !== '' &&
                            setStoreSubscriptionFPR(
                                data.profile[
                                    'subscription.filters.product_quid_result'
                                ]
                            );
                        data.profile['subscription.filters.subscribers_q'] &&
                            data.profile[
                                'subscription.filters.subscribers_q'
                            ] !== '' &&
                            setStoreSubscriptionFSQ(
                                data.profile[
                                    'subscription.filters.subscribers_q'
                                ]
                            );
                        data.profile[
                            'subscription.filters.subscribers_result'
                        ] &&
                            data.profile[
                                'subscription.filters.subscribers_result'
                            ] !== '' &&
                            setStoreSubscriptionFSR(
                                data.profile[
                                    'subscription.filters.subscribers_result'
                                ]
                            );
                        data.profile['subscription.savedQueries'] && data.profile['subscription.savedQueries'] !== '' && setStoreSubscriptionSQ(data.profile['subscription.savedQueries']);
                        data.profile['preferences.school.datagrid.columns'] &&
                            data.profile[
                                'preferences.school.datagrid.columns'
                            ] !== '' &&
                            setStoreSchoolPDC(
                                data.profile[
                                    'preferences.school.datagrid.columns'
                                ]
                            );
                        data.profile['school.listParams'] &&
                            data.profile['school.listParams'] !== '' &&
                            setStoreSchoolLP(data.profile['school.listParams']);
                        data.profile['school.filterList'] &&
                            data.profile['school.filterList'] !== '' &&
                            setStoreSchoolFL(data.profile['school.filterList']);
                        data.profile['school.savedQueries'] && data.profile['school.savedQueries'] !== '' && setStoreSchoolSQ(data.profile['school.savedQueries']);
                        // data.profile["school.pupil.subscription.listParams"] && data.profile["school.pupil.subscription.listParams"] !== "" && setStoreSchoolPSL(data.profile["school.pupil.subscription.listParams"]);
                        // data.profile["school.teacher.subscription.listParams"] && data.profile["school.teacher.subscription.listParams"] !== "" && setStoreSchoolTSL(data.profile["school.teacher.subscription.listParams"]);
                        data.profile['preferences.group.datagrid.columns'] &&
                            data.profile[
                                'preferences.group.datagrid.columns'
                            ] !== '' &&
                            setStoreGroupPDC(
                                data.profile[
                                    'preferences.group.datagrid.columns'
                                ]
                            );
                        data.profile['group.listParams'] &&
                            data.profile['group.listParams'] !== '' &&
                            setStoreGroupLP(data.profile['group.listParams']);
                        data.profile['group.filterList'] &&
                            data.profile['group.filterList'] !== '' &&
                            setStoreGroupFL(data.profile['group.filterList']);
                        data.profile['group.filters.schools_q'] &&
                            data.profile['group.filters.schools_q'] !== '' &&
                            setStoreGroupFSQ(
                                data.profile['group.filters.schools_q']
                            );
                        data.profile['group.filters.schools_result'] &&
                            data.profile['group.filters.schools_result'] !==
                                '' &&
                            setStoreGroupFSR(
                                data.profile['group.filters.schools_result']
                            );
                        data.profile['group.savedQueries'] && data.profile['group.savedQueries'] !== '' && setStoreGroupSQ(data.profile['group.savedQueries']);
                        data.profile[
                            'preferences.announcement.datagrid.columns'
                        ] &&
                            data.profile[
                                'preferences.announcement.datagrid.columns'
                            ] !== '' &&
                            setStoreAnnouncementPDC(
                                data.profile[
                                    'preferences.announcement.datagrid.columns'
                                ]
                            );
                        data.profile['announcement.listParams'] &&
                            data.profile['announcement.listParams'] !== '' &&
                            setStoreAnnouncementLP(
                                data.profile['announcement.listParams']
                            );
                        data.profile['announcement.filterList'] &&
                            data.profile['announcement.filterList'] !== '' &&
                            setStoreAnnouncementFL(
                                data.profile['announcement.filterList']
                            );
                        data.profile['announcement.savedQueries'] && data.profile['announcement.savedQueries'] !== '' && setStoreAnnouncementSQ(data.profile['announcement.savedQueries']);
                        setStoreGetFromServer(
                            parseInt(
                                new Date(Date.now()).setMinutes(0).toString()
                            ).toString()
                        );
                        //console.log('From server');
                    } else {
                        setStoreGetFromServer(
                            parseInt(
                                new Date(Date.now()).setMinutes(0).toString()
                            ).toString()
                        );
                        //console.log('UPDATE Fetch');
                    }
                    return data;
                }),
        enabled: !!token, // Conditionally enable the query based on the presence of the token
        refetchInterval: 30000,
    });

    if (!data) {
        return null;
    }
    //console.log(json);
    return (
        <>
            {/* <CustomOnlineStoreItem storeKey="student.filterList" />
            <CustomOnlineStoreItem storeKey="preferences.student.datagrid.columns" /> */}
        </>
    );
};

export default CustomOnlineStore;
//CustomOnlineStoreItem
