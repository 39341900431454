import * as React from 'react';
import { FC, useState } from 'react';
import { useRecordContext } from 'react-admin';

import { useDataProvider, useRefresh, useEditContext } from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import lodash from 'lodash';
import { printIntrospectionSchema } from 'graphql';

//import { FieldProps } from '../../types';

interface Props {
    url: string;
    urlparams?: any;
    icon?: any;
    hideIf?: [any];
    // hideIfParent?: [any];
    style?: any;
    gotourl?: boolean;
    gotoexternalurl?: boolean;
    label?: string;
}

const CustomUrlButton: FC<Props> = ({
    url,
    urlparams = [],
    icon = '',
    hideIf = undefined,
    // hideIfParent = undefined,
    style = {},
    gotourl = false,
    gotoexternalurl = false,
    label = "",
    //...props
}) => {

    const parent = useEditContext(); //tällä haetaan parent
    urlparams.forEach(param => {
        url = url.replace(
            `##${param}##`,
            lodash.get(parent.record, param) != undefined
                ? lodash.get(parent.record, param)
                : ''
        );
    });
    
    const record = useRecordContext();
    urlparams.forEach(param => {
        url = url.replace(
            `#${param}#`,
            lodash.get(record, param) != undefined
                ? lodash.get(record, param)
                : ''
        );
    });

    //console.log(record);

    const params = '';
    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    const [loading, setLoading] = useState(false);

    const handleOnClick: any = (e) => {
        e.stopPropagation(); //Lisätty, että pystyy käyttämään linkkiä ilman, että se triggeröi datagridin riviä
        if (gotourl)
            window.location.href='/#/'+url;
        else if (gotoexternalurl)
            window.location.href=url;
        else {
            setLoading(true);
            dataProvider
                .getAny(url, params)
                .then(({}) => {
                    refresh();
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    refresh();
                    setLoading(false);
                });
            }
    };

    let hideElement = false;

    if (hideIf) {
        for (const h in hideIf) {
            if (lodash.get(record, hideIf[h]["key"]) == hideIf[h]["val"]) 
                hideElement = true;
            else if (lodash.get(record, hideIf[h]["key"]) == lodash.get(record, hideIf[h]["val_key"])) 
                hideElement = true;
        }
    }

    // if (hideIfParent) {
    //     for (const h in hideIfParent) {
    //         if (lodash.get(parent, hideIfParent[h]["key"]) == hideIfParent[h]["val"]) 
    //             hideElement = true;
    //         else if (lodash.get(parent, hideIfParent[h]["key"]) == lodash.get(parent, hideIfParent[h]["val_key"])) 
    //             hideElement = true;
    //     }
    // }

    if (hideElement) return null;

    if (loading) return <CircularProgress color="primary" style={{width: '1.5em', height: '1.5em'}} />;
    
    //return <IconButton onClick={handleOnClick} style={style} key={`ali_${lodash.get(record, 'guid')}`} disabled={loading}>{icon} {label}</IconButton>
    return <Button onClick={handleOnClick} style={style} key={`ali_${lodash.get(record, 'guid')}`} disabled={loading}>{label} {icon}</Button>
};

export default CustomUrlButton;
